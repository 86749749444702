import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import CO from "../../images/co-card.png";
import BC from "../../images/barclay-card.png";
import HSBC from "../../images/hsbc-card.jpeg";
import MS from "../../images/ms-card.png";

import NW from "../../images/natwest-card.png";
import SB from "../../images/sainsburys-card.png";
import TC from "../../images/tesco-card.png";
import VC from "../../images/virgin-card.png";

const imgArray = [CO, BC, HSBC, MS, NW, SB, TC, VC];

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const maxHeight = typeof window !== `undefined` ? window.outerHeight : 0;
const maxWidth = typeof window !== `undefined` ? window.outerWidth : 0;
const cardHeight = 141;
const cardWidth = 225;

export default function Start() {
  const [coords, setCoords] = useState({
    x: getRandomInt(0, maxWidth - cardWidth),
    y: getRandomInt(0, maxHeight - cardHeight)
  });
  const [curr, setCurr] = useState(0);
  const changeImage = () => {
    let newValue = curr + 1;
    if (newValue >= imgArray.length) {
      newValue = 0;
    }
    setCurr(newValue);
  };

  useInterval(() => changeImage(), 200);

  const move = () => {
    setCoords({
      x: getRandomInt(0, maxWidth - cardWidth),
      y: getRandomInt(0, maxHeight - cardHeight)
    });
  };

  return (
    <Layout>
      <SEO title="CARD" />
      <div className="pad-30">
        <div
          className="cant-touch-this"
          onMouseEnter={() => move()}
          style={{ left: coords.x, top: coords.y }}
        >
          <img src={imgArray[curr]} style={{ width: "100%" }} />
        </div>
      </div>
      <div className="is-white-bg is-pink margin-2 text-align-center">
        <h4>
          If you can click this magical (super glitchy) card, you can have it.{" "}
          <br /> If you're struggling,{" "}
        </h4>

        <Link
          to="/card/amex"
          className="btn btn-link is-blue-bg is-white margin-15-t"
          style={{ textDecoration: "none" }}
        >
          Try AMEX?
        </Link>
      </div>
    </Layout>
  );
}
